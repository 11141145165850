import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { NetworkActionBar } from '~/components/ActionBars/NetworkActionBar';
import { Button } from '~/components/Button';
import { SponsorshipDecimals } from '~/components/Decimals';
import { NetworkHelmet } from '~/components/Helmet';
import Layout, { LayoutColumn } from '~/components/Layout';
import { LoadMoreButton } from '~/components/LoadMore';
import NetworkPageSegment, { SegmentGrid } from '~/components/NetworkPageSegment';
import { OperatorIdCell } from '~/components/Table';
import { getSpotApy } from '~/getters';
import { useAllOperatorsQuery, useDelegationsForWalletQuery, useOperatorForWalletQuery, } from '~/hooks/operators';
import { useTableOrder } from '~/hooks/useTableOrder';
import { ScrollTableCore } from '~/shared/components/ScrollTable/ScrollTable';
import Tabs, { Tab } from '~/shared/components/Tabs';
import { useIsWalletLoading, useWalletAccount } from '~/shared/stores/wallet';
import { saveOperator } from '~/utils';
import { useCurrentChainFullName, useCurrentChainId, useCurrentChainSymbolicName, } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { useUrlParams } from '~/hooks/useUrlParams';
var TabOption;
(function (TabOption) {
    TabOption["AllOperators"] = "all";
    TabOption["MyDelegations"] = "my";
})(TabOption || (TabOption = {}));
const PAGE_SIZE = 20;
const DEFAULT_ORDER_BY = 'totalValue';
const DEFAULT_ORDER_DIRECTION = 'desc';
const DEFAULT_TAB = TabOption.AllOperators;
function isTabOption(value) {
    return value === TabOption.AllOperators || value === TabOption.MyDelegations;
}
export const OperatorsPage = () => {
    const wallet = useWalletAccount();
    const isWalletLoading = useIsWalletLoading();
    const [params] = useSearchParams();
    const tab = params.get('tab');
    const selectedTab = isTabOption(tab) ? tab : DEFAULT_TAB;
    const [searchQuery, setSearchQuery] = useState(params.get('search') || '');
    const { orderBy, orderDirection, setOrder } = useTableOrder({
        orderBy: params.get('orderBy') || DEFAULT_ORDER_BY,
        orderDirection: params.get('orderDir') || DEFAULT_ORDER_DIRECTION,
    });
    useUrlParams([
        {
            param: 'tab',
            value: selectedTab,
            defaultValue: DEFAULT_TAB,
        },
        {
            param: 'orderBy',
            value: orderBy,
            defaultValue: DEFAULT_ORDER_BY,
        },
        {
            param: 'orderDir',
            value: orderDirection,
            defaultValue: DEFAULT_ORDER_DIRECTION,
        },
        {
            param: 'search',
            value: searchQuery,
            defaultValue: '',
        },
    ]);
    const allOperatorsQuery = useAllOperatorsQuery({
        batchSize: PAGE_SIZE,
        searchQuery,
        orderBy,
        orderDirection,
    });
    const myDelegationsQuery = useDelegationsForWalletQuery({
        address: wallet,
        pageSize: PAGE_SIZE,
        searchQuery,
    });
    const chainId = useCurrentChainId();
    const chainName = useCurrentChainSymbolicName();
    const operatorQuery = useOperatorForWalletQuery(wallet);
    const { data: operator = null } = operatorQuery;
    const currentQuery = selectedTab === TabOption.AllOperators ? allOperatorsQuery : myDelegationsQuery;
    const navigate = useNavigate();
    useEffect(() => {
        if (!wallet && !isWalletLoading) {
            navigate(R.operators(routeOptions(chainName, {
                tab: TabOption.AllOperators,
            })));
        }
    }, [wallet, isWalletLoading, navigate, chainName]);
    return (React.createElement(Layout, null,
        React.createElement(NetworkHelmet, { title: "Operators" }),
        React.createElement(NetworkActionBar, { searchEnabled: true, searchValue: searchQuery, onSearch: setSearchQuery, leftSideContent: React.createElement(Tabs, { onSelectionChange: (value) => {
                    navigate(R.operators(routeOptions(chainName, { tab: value })));
                }, selection: selectedTab, fullWidthOnMobile: true },
                React.createElement(Tab, { id: TabOption.AllOperators }, "All Operators"),
                React.createElement(Tab, { id: TabOption.MyDelegations, disabled: !wallet }, "My Delegations")), rightSideContent: operator ? (React.createElement(Button, { as: Link, to: R.operator(operator.id, routeOptions(chainName)) }, "View my Operator")) : (React.createElement(Button, { onClick: () => {
                    saveOperator(chainId, undefined, {
                        onDone(id, blockNumber) {
                            navigate(R.operator(id, routeOptions(chainId, {
                                b: blockNumber,
                            })));
                        },
                    });
                }, disabled: !wallet || !!operator || operatorQuery.isFetching }, "Become an Operator")) }),
        React.createElement(LayoutColumn, null,
            React.createElement(SegmentGrid, null,
                React.createElement(NetworkPageSegment, { foot: true, title: React.createElement("h2", null, selectedTab === TabOption.AllOperators ? (React.createElement(React.Fragment, null, "All Operators")) : (React.createElement(React.Fragment, null, "My Delegations"))) },
                    selectedTab === TabOption.AllOperators ? (React.createElement(OperatorsTable, { query: allOperatorsQuery, orderBy: orderBy, orderDirection: orderDirection, onOrderChange: setOrder })) : (React.createElement(DelegationsTable, { query: myDelegationsQuery })),
                    currentQuery.hasNextPage && (React.createElement(LoadMoreButton, { disabled: currentQuery.isLoading || currentQuery.isFetching, onClick: () => void currentQuery.fetchNextPage(), kind: "primary2" }, "Load more")))))));
};
function DelegationsTable({ query, }) {
    // We want to hide delegations to broken operator contract version 1
    // as we cannot get rid of them otherwise
    const elements = query.data?.pages
        .flatMap((page) => page.elements)
        .filter((d) => d.contractVersion !== 1) || [];
    const chainName = useCurrentChainSymbolicName();
    const chainFullName = useCurrentChainFullName();
    const wallet = useWalletAccount();
    return (React.createElement(ScrollTableCore, { elements: elements, isLoading: query.isLoading || query.isFetching || query.isFetchingNextPage, columns: [
            {
                displayName: 'Operator',
                valueMapper: ({ id, metadata: { name, imageUrl } }) => (React.createElement(OperatorIdCell, { operatorId: id, operatorName: name, imageUrl: imageUrl })),
                align: 'start',
                isSticky: true,
                key: 'operatorId',
            },
            {
                displayName: 'My delegation',
                valueMapper: (element) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: wallet ? element.share(wallet) : 0n })),
                align: 'start',
                isSticky: false,
                key: 'myShare',
            },
            {
                displayName: 'Total stake',
                valueMapper: (element) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: element.valueWithoutEarnings })),
                align: 'end',
                isSticky: false,
                key: 'totalStake',
            },
            {
                displayName: "Owner's cut",
                valueMapper: (element) => `${element.operatorsCut}%`,
                align: 'end',
                isSticky: false,
                key: 'operatorsCut',
            },
            {
                displayName: 'APY',
                valueMapper: (element) => `${(element.apy * 100).toFixed(0)}%`,
                align: 'end',
                isSticky: false,
                key: 'apy',
            },
            {
                displayName: 'Sponsorships',
                valueMapper: (element) => element.stakes.length,
                align: 'end',
                isSticky: false,
                key: 'sponsorships',
            },
        ], noDataFirstLine: `You have not delegated to any operator on the ${chainFullName} chain.`, linkMapper: (element) => R.operator(element.id, routeOptions(chainName)) }));
}
function OperatorsTable({ query, orderBy, orderDirection, onOrderChange, }) {
    const elements = query.data?.pages.flatMap((page) => page.elements) || [];
    const chainName = useCurrentChainSymbolicName();
    const chainFullName = useCurrentChainFullName();
    return (React.createElement(ScrollTableCore, { elements: elements, isLoading: query.isLoading || query.isFetching || query.isFetchingNextPage, orderDirection: orderDirection, orderBy: orderBy, onOrderChange: onOrderChange, columns: [
            {
                displayName: 'Operator',
                valueMapper: ({ id, metadata: { name, imageUrl } }) => (React.createElement(OperatorIdCell, { operatorId: id, operatorName: name, imageUrl: imageUrl })),
                align: 'start',
                isSticky: true,
                key: 'operatorId',
            },
            {
                displayName: 'Total stake',
                valueMapper: (element) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: element.valueWithoutEarnings })),
                align: 'start',
                isSticky: false,
                key: 'totalValue',
                sortable: true,
            },
            {
                displayName: 'Deployed stake',
                valueMapper: (element) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: element.totalStakeInSponsorshipsWei })),
                align: 'end',
                isSticky: false,
                key: 'deployed',
                sortable: true,
            },
            {
                displayName: "Owner's cut",
                valueMapper: (element) => `${element.operatorsCut}%`,
                align: 'end',
                isSticky: false,
                key: 'operatorCut',
                sortable: true,
            },
            {
                displayName: 'APY',
                valueMapper: (element) => `${(getSpotApy(element) * 100).toFixed(0)}%`,
                align: 'end',
                isSticky: false,
                key: 'apy',
            },
            {
                displayName: 'Sponsorships',
                valueMapper: (element) => element.stakes.length,
                align: 'end',
                isSticky: false,
                key: 'sponsorshipCount',
            },
        ], noDataFirstLine: `No operators found on the ${chainFullName} chain.`, linkMapper: (element) => R.operator(element.id, routeOptions(chainName)) }));
}
