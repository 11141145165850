import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Reason, useDiscardableEffect } from 'toasterhea';
import { COLORS } from '~/shared/utils/styled';
import SvgIcon from '~/shared/components/SvgIcon';
import { RejectionReason } from '~/utils/exceptions';
export function isAbandonment(e) {
    return (e === RejectionReason.BackButton ||
        e === RejectionReason.Backdrop ||
        e === RejectionReason.CancelButton ||
        e === RejectionReason.CloseButton ||
        e === RejectionReason.EscapeKey ||
        e === Reason.Host ||
        e === Reason.Unmount ||
        e === Reason.Update);
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-s6fn4l" }) `
    background: #f5f5f5;
    color: #323232;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
const Column = styled.div.withConfig({ displayName: "Column", componentId: "sc-fzagel" }) `
    margin: 0 auto;
    max-width: 528px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
const ScrollableContent = styled.div.withConfig({ displayName: "ScrollableContent", componentId: "sc-3sac7r" }) `
    flex: 1;
    overflow-y: auto;
    padding: 0 16px;
    
    @media (min-width: 768px) {
        padding: 0;
    }
`;
const Nav = styled.div.withConfig({ displayName: "Nav", componentId: "sc-fyo131" }) `
    box-sizing: content-box;
    height: 64px;
    padding: 48px 16px;
    
    @media (min-width: 768px) {
        padding: 48px 0;
    }

    button {
        appearance: none;
        background: none;
        border: 0;
        padding: 0;
        width: 64px;
        height: 100%;
        display: flex;
        align-items: center;
        transform: translateX(-31%);
    }

    button svg {
        display: block;
        margin: 0 auto;
    }

    & + h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 20px;
        padding: 0 16px;
        
        @media (min-width: 768px) {
            padding: 0;
        }
    }
`;
const BackButtonIcon = styled(SvgIcon).withConfig({ displayName: "BackButtonIcon", componentId: "sc-1gl78h5" }) `
    color: ${COLORS.primaryLight};
`;
export const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-6y7s01" }) `
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 16px;
    padding: 0 16px 16px;
    background: #f5f5f5;
    
    @media (min-width: 768px) {
        padding: 0 0 16px;
    }

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        align-items: stretch;
        
        button {
            width: 100%;
        }
    }

    button {
        min-width: 120px;
    }
`;
export default function ProjectModal({ backable = false, children, closeOnEscape = false, onReject, title, }) {
    useDiscardableEffect((discard) => void setTimeout(discard));
    useEffect(() => {
        function onKeyDown(e) {
            if (e.key === 'Escape' && closeOnEscape) {
                onReject?.(RejectionReason.EscapeKey);
            }
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onReject, closeOnEscape]);
    return (React.createElement(Root, null,
        React.createElement(Column, null,
            React.createElement(Nav, null, backable && (React.createElement("button", { type: "button", onClick: () => void onReject?.(RejectionReason.BackButton) },
                React.createElement(BackButtonIcon, { name: "backArrow" })))),
            !!title && React.createElement("h2", null,
                "\u200C",
                title),
            React.createElement(ScrollableContent, null, children),
            React.createElement(Actions, null))));
}
