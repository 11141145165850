import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { NetworkActionBar } from '~/components/ActionBars/NetworkActionBar';
import { Button } from '~/components/Button';
import { NetworkHelmet } from '~/components/Helmet';
import Layout, { LayoutColumn } from '~/components/Layout';
import NetworkPageSegment, { SegmentGrid } from '~/components/NetworkPageSegment';
import { QueriedSponsorshipsTable } from '~/components/QueriedSponsorshipsTable';
import { SponsorshipFilterButton, defaultFilters, } from '~/components/SponsorshipFilterButton';
import { useAllSponsorshipsQuery, useCreateSponsorship, useIsCreatingSponsorshipForWallet, useSponsorshipsForCreatorQuery, } from '~/hooks/sponsorships';
import { useTableOrder } from '~/hooks/useTableOrder';
import Tabs, { Tab } from '~/shared/components/Tabs';
import { useWalletAccount, useIsWalletLoading } from '~/shared/stores/wallet';
import { useCurrentChainFullName, useCurrentChainId, useCurrentChainSymbolicName, } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { useUrlParams } from '~/hooks/useUrlParams';
var TabOption;
(function (TabOption) {
    TabOption["AllSponsorships"] = "all";
    TabOption["MySponsorships"] = "my";
})(TabOption || (TabOption = {}));
const PAGE_SIZE = 20;
const DEFAULT_ORDER_BY = 'remainingWei';
const DEFAULT_ORDER_DIRECTION = 'desc';
const DEFAULT_TAB = TabOption.AllSponsorships;
function isTabOption(value) {
    return value === TabOption.AllSponsorships || value === TabOption.MySponsorships;
}
export const SponsorshipsPage = () => {
    const [params] = useSearchParams();
    const initialFilters = {
        ...defaultFilters,
        ...Object.keys(defaultFilters).reduce((acc, key) => {
            if (params.has(key)) {
                return { ...acc, [key]: params.get(key) === 'true' };
            }
            return acc;
        }, {}),
    };
    const [filters, setFilters] = useState(initialFilters);
    const wallet = useWalletAccount();
    const isWalletLoading = useIsWalletLoading();
    const { orderBy, orderDirection, setOrder } = useTableOrder({
        orderBy: params.get('orderBy') || DEFAULT_ORDER_BY,
        orderDirection: params.get('orderDir') || DEFAULT_ORDER_DIRECTION,
    });
    const tab = params.get('tab');
    const selectedTab = isTabOption(tab) ? tab : DEFAULT_TAB;
    const [searchQuery, setSearchQuery] = useState(params.get('search') || '');
    useUrlParams([
        {
            param: 'tab',
            value: selectedTab,
            defaultValue: DEFAULT_TAB,
        },
        {
            param: 'orderBy',
            value: orderBy,
            defaultValue: DEFAULT_ORDER_BY,
        },
        {
            param: 'orderDir',
            value: orderDirection,
            defaultValue: DEFAULT_ORDER_DIRECTION,
        },
        {
            param: 'search',
            value: searchQuery,
            defaultValue: '',
        },
        ...Object.entries(defaultFilters).map(([key, value]) => ({
            param: key,
            value: filters[key].toString(),
            defaultValue: value.toString(),
        })),
    ]);
    const allSponsorshipsQuery = useAllSponsorshipsQuery({
        pageSize: PAGE_SIZE,
        searchQuery,
        orderBy,
        orderDirection,
        filters,
    });
    const mySponsorshipsQuery = useSponsorshipsForCreatorQuery(wallet, {
        pageSize: PAGE_SIZE,
        searchQuery,
        orderBy,
        orderDirection,
        filters,
    });
    const navigate = useNavigate();
    const chainName = useCurrentChainSymbolicName();
    useEffect(() => {
        if (!wallet && !isWalletLoading) {
            navigate(R.sponsorships(routeOptions(chainName, {
                tab: TabOption.AllSponsorships,
            })));
        }
    }, [wallet, navigate, chainName, isWalletLoading]);
    const createSponsorship = useCreateSponsorship();
    const isCreatingSponsorship = useIsCreatingSponsorshipForWallet(wallet);
    const chainId = useCurrentChainId();
    const chainFullName = useCurrentChainFullName();
    return (React.createElement(Layout, null,
        React.createElement(NetworkHelmet, { title: "Sponsorships" }),
        React.createElement(NetworkActionBar, { searchEnabled: true, searchValue: searchQuery, onSearch: setSearchQuery, leftSideContent: React.createElement(Tabs, { onSelectionChange: (value) => {
                    navigate(R.sponsorships(routeOptions(chainName, { tab: value })));
                }, selection: selectedTab, fullWidthOnMobile: true },
                React.createElement(Tab, { id: TabOption.AllSponsorships }, "All sponsorships"),
                React.createElement(Tab, { id: TabOption.MySponsorships, disabled: !wallet }, "My sponsorships")), rightSideContent: React.createElement(Button, { waiting: isCreatingSponsorship, onClick: () => {
                    createSponsorship(chainId, wallet, {
                        onDone(id, blockNumber) {
                            navigate(R.sponsorship(id, routeOptions(chainId, {
                                b: blockNumber,
                            })));
                        },
                    });
                }, disabled: !wallet }, "Create sponsorship") }),
        React.createElement(LayoutColumn, null,
            React.createElement(SegmentGrid, null,
                React.createElement(NetworkPageSegment, { foot: true, title: React.createElement(Title, null,
                        React.createElement("h2", null, selectedTab === TabOption.AllSponsorships ? (React.createElement(React.Fragment, null, "All sponsorships")) : (React.createElement(React.Fragment, null, "My sponsorships"))),
                        React.createElement(SponsorshipFilterButton, { filter: filters, onFilterChange: setFilters })) },
                    React.createElement(QueriedSponsorshipsTable, { query: selectedTab === TabOption.AllSponsorships
                            ? allSponsorshipsQuery
                            : mySponsorshipsQuery, noDataFirstLine: selectedTab === TabOption.AllSponsorships
                            ? `No sponsorships found on the ${chainFullName} chain.`
                            : `You do not have any sponsorships on the ${chainFullName} chain yet.`, orderBy: orderBy, orderDirection: orderDirection, onOrderChange: setOrder }))))));
};
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1ds6rh1" }) `
    display: grid;
    grid-template-columns: 1fr auto;
`;
